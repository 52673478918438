.section-order {
  padding: 13px 0 13px 0;
  margin: 12px 0;
  text-align: center;
  display: flex;
  flex-direction: column;

  .amount-currency {
    font-size: 2.5em;
    color: var(--color-additional);
    margin: 12px;
  }

  span {
    align-self: center;
    font-size: 0.7em;
  }

  .method-label {
    line-height: 150%;
    color: #000000;
    font-weight: 700;
    font-size: var(--fontSize-header);
  }
}

.nav-grid {
  display: grid;
  //grid-template-columns: repeat(3, 1fr);
  grid-template-columns: 0.5fr 3.5fr 0.5fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.payment-method-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.description-text {
  line-break: anywhere;
  text-align: left;
}
