.inpayQr {
  .container {
    background: #fff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 320px;
    width: 100%;
  }

  h1 {
    font-size: 24px;
    color: #4facfe;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
  }
  #qrcode {
    display: flex;
    justify-content: center;
  }
}