.confirmation-wrapper {
  width: 100%;
  box-shadow: 5px 5px 60px rgb(38 38 115 / 10%);
  border-radius: 30px;
  max-width: 679px;
  padding: 0 70px 0 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  align-items: center;
  padding-bottom: 35px;

  .button-submit {
    width: 258px;
    height: 45px;
  }

  .confirm-message {
    font-weight: 600;
    font-size: var(--fontSize-body2);
    line-height: 24px;
    width: 100%;
    text-align: center;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .method-label {
    font-weight: 700;
    font-size: var(--fontSize-header);
    line-height: 36px;
    color: #000000;
  }

  .method-description {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    margin-bottom: 35px;
  }

  .navbar {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: 10px -100px 7px 0;
  }

  .amounts-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    gap: 11px;
    margin-bottom: 55px;

    .confirm-amount-currency {
      display: grid;
      grid-auto-columns: 1fr;
      grid-template-columns: 3fr 1fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-template-areas: ". .";
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 0;

      span {
        background: var(--color-shade100);
        border: 2px solid var(--color-primary);
        box-sizing: border-box;
        border-radius: 15px;
        padding: 8px 20px;
        font-weight: 600;
        font-size: var(--fontSize-body2);
        line-height: 24px;
        color: var(--color-primary);
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 584px) {
  .confirmation-wrapper {
    padding: 0 20px 0 20px;
    border-radius: 0;

    .button-submit {
      margin-bottom: 25px;
    }

    .navbar {
      margin-right: 0;
    }
  }
}
