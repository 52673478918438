.amounts {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  input {
    margin-left: 0;
  }

  label {
    position: relative;
    color: var(--color-primary);
    font-size: var(--fontSize-body2);
    line-height: 150%;
    font-weight: 600;
    text-align: center;
    width: 70px;
    height: 34px;
    cursor: pointer;
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-shade100);
    border: 2px solid var(--color-primary);
    border-radius: 15px;
  }

  .radio-amount {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 7px;

    &:last-child {
      margin-right: 0;
    }

    input {
      display: none;
    }
  }

  .wrapper-amount-radios {
    display: flex;
    flex-direction: row;
  }

  input:checked + label {
    background: var(--color-primaryGradient200);
    color: white;
  }
}

.predefine-amount-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .method-label {
    line-height: 150%;
    color: #000000;
    font-weight: 700;
    font-size: var(--fontSize-header);
    margin-bottom: 15px;
  }

  @media only screen and (max-width: 675px) {
    .method-label {
      text-align: center;
      width: 100%;
    }
  }
}

.manual-label {
  label {
    padding-top: 20px;
  }
}

.amount-top {
  display: flex;
  align-items: center;
}

.amount-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 4px;
  width: 155px;
}

.currency-input-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #7b6dec;
}

.manual.amount-wrapper {
  .manual-label {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: row;

    input {
      background-color: transparent;
      font-size: 18px;
      font-weight: 500;
      color: var(--color-primary);
      padding: 4px 5px 4px 30px;
      text-align: center;

      @media (max-width: 480px) {
        font-weight: 600;
      }
    }

    svg {
      width: 22px;

      path {
        fill: var(--color-primary);
      }
    }

    .error__message-block {
      font-weight: 400;
    }
  }

  .currency {
    color: var(--color-primary);
    font-size: 18px;
    font-weight: 700;
  }

  .method-label {
    align-self: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
  }

  .wrapper-input {
    width: 100%;

    label {
      padding-top: 0;
    }
  }
  .error__message-block {
    top: 150%;
  }
}

.button-toggle-amount-input {
  cursor: pointer;
  color: var(--color-primary);
  font-size: var(--fontSize-body4);
  line-height: 150%;
  text-decoration: underline;
  text-align: end;
  margin-top: 5px;
  margin-bottom: 0;
}

.amount-range-label {
  display: flex;
  gap: 12px;
}

.range-and-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #8a939c;
}

.amount-range-label small {
  font-size: 0.69rem;
}

.amount-input-wrapper {
  width: 100%;
  margin-top: 20px;
}

@media only screen and (max-width: 675px) {
  .manual-label {
    label {
      padding-top: 0;
    }
  }

  .button-toggle-amount-input {
    text-align: center;
  }
}

.fixed-amount-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 15px;

  .fixed-amount-details {
    .fixed-amount {
      display: flex;
      background: var(--color-shade100);
      border-radius: 15px;
      height: 53px;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: var(--fontSize-body1);
      line-height: 150%;
      color: var(--color-primary);
      padding: 8px 32px;
    }

    .fixed-amount-description {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #06192c;
      text-align: center;
    }
  }
}

.submit-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.fixed-amount-card-wrapper {
  display: flex;
  align-items: baseline;
  flex-direction: column;

  .label-topay {
    font-size: 14px;
    line-height: 20px;
    color: #a1a3be;
  }

  .fixed-amount-details {
    .fixed-amount {
      display: flex;
      font-weight: 700;
      font-size: var(--fontSize-header);
      line-height: 150%;
      color: var(--color-primary);
      direction: rtl;
      text-align: left;
    }
  }
}

@media only screen and (max-width: 675px) {
  .submit-wrapper {
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }

  .fixed-amount-card-wrapper {
    align-items: center;

    .label-topay {
      font-size: 14px;
      line-height: 20px;
      color: #a1a3be;
    }
  }
}
