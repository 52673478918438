.wrapper-checkbox input {
  width: 50px;
}

.wrapper-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  background: #f2f0fd;
  border: 1px solid #e6e3fb;
  border-radius: 10px;
}
