@import "../../../scss-variables.scss";

.wrapper-select-blockchain {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 0 10px;
  margin-bottom: 16px;
  label {
    line-height: 27px;
    font-weight: 500;
    color: #8a939c;
    font-size: 15px;
    align-self: center;
  }

  select {
    border-radius: 20px;
    width: 254px;
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 0 10px 20px;
    border: 1px solid var(--color-shade200);
    font-size: 15px;
    text-align: left;
    font-family: "Roboto";
    text-align-last: left;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../../assets/img/down-arrow.png");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: center;

    &:focus {
      outline: none;
      border: 1px solid var(--color-primary);
      background-image: url("../../../assets/img/back.png");
    }

    &.error {
      border-bottom: 1px solid var(--color-shade200) !important;
    }
  }
}

select:invalid {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: var(----color-shade200);
}

option:first-child {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: var(----color-shade200);
}

select:invalid option:not(:first-child) {
  color: #a1a3be;
}

@media only screen and (max-width: 675px) {
  .wrapper-select-blockchain {
    flex-direction: column;

    select {
      width: 100%;
    }

    label {
      padding-top: 0;
      padding-bottom: 10px;
      align-self: flex-start;
    }
  }
}
