.header-methods {
  margin-bottom: 16px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: var(--fontSize-header);
  color: var(--navy-blue);
  line-height: 150%;
}

.checkbox-input-method {
  display: none;

  &:checked+label {
    box-shadow: 0 10px 6px -6px #777;
  }
}

.checkbox-label-method {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
  width: 100%;
  height: 100%;
  padding: 12px;
  border-radius: 30px;
  box-shadow: 5px 5px 60px rgba(38, 38, 115, 0.1);
  transition: 0.5s;
  cursor: pointer;

  &:hover {
    background: var(--color-primaryGradient200);
    box-shadow: 30px 30px 60px rgba(38, 38, 115, 0.26);

    path {
      fill: #fff;
    }

    span {
      color: var(--color-white100);
    }
  }

  &:active {
    background: linear-gradient(147.38deg, #6656e9 0%, #7b6dec 100%);
    box-shadow: 10px 10px 60px rgba(36, 94, 254, 0.48);

    span {
      color: var(--color-white100);
    }
  }

  .logo-wrapper {
    height: 30%;
    display: flex;
    align-items: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  span {
    color: var(--color-shade400);
    font-size: var(--fontSize-body2);
    line-height: 24px;
    font-weight: 600;
  }
}

.method-group {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  max-width: 900px;
}

.card-method {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 156px;
  height: 150px;
  background: var(--color-white100);
  box-shadow: 5px 5px 60px rgb(38 38 115 / 10%);
  border-radius: 30px;
}