.additional-fields {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;

  .wrapper-input {
    width: 50%;
    padding: 0 10px;

    &-full {
      width: 100%;
    }
    label {
      padding-top: 10px;
    }
  }

  .additional-item {
    width: 50%;

    .wrapper-input {
      width: 100%;
    }
  }

  .error {
    &__img {
      position: relative;
      right: 6px;
    }
  }

  @media only screen and (max-width: 675px) {
    .wrapper-input {
      flex-direction: column;
    }

    .wrapper-input {
      width: 100%;

      label {
        padding-top: 0;
        padding-bottom: 10px;
      }
    }
  }
}
