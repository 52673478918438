.secure-logos-wrapper {
  padding-top: 18px;
  padding-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 45px;

  img {
    width: 60px;
  }

  @media (max-width: 480px) {
    gap: 40px;
  }
}
