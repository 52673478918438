.copay {
  .container {
    background-color: white;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 420px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .logo {
    font-size: 30px;
    font-weight: bold;
    color: #b59f78;
    display: flex;
    align-items: center;
  }

  .logo img {
    height: 50px;
    margin-right: 8px;
  }

  h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 15px 0;
    text-align: center;
  }

  .qr-code {
    margin: 15px auto;
    width: 200px;
    height: 200px;
    background: gray;
    display: block;
  }

  .details {
    font-size: 13px;
    color: #666;
    margin-bottom: 8px;
    text-align: center;
  }

  .details-or {
    font-size: 16px;
    color: black;
    margin-bottom: 8px;
    text-align: center;
  }

  .left-aligned {
    text-align: left;
    width: 100%;
  }

  .copy-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 12px;
    margin-left: 3px;
    padding: 0;
    vertical-align: middle;
    display: inline-flex;
    align-items: flex-start;
    position: relative;
    top: -2px;
  }

  .copy-btn img {
    width: 12px;
    height: 14px;
  }

  .timer {
    font-size: 16px;
    font-weight: bold;
    color: #d9534f;
  }

  .payment-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin: 15px 0;
    opacity: 0.8;
  }

  .payment-icons img {
    height: 70px;
  }

  .payment-box {
    border: 1.5px solid #000;
    padding: 1px 12px;
    border-radius: 8px;
    background-color: white;
    text-align: left;
    margin-top: 12px;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
  }

  .utr-input {
    width: 100%;
    padding: 12px;
    margin-top: 12px;
    border: 1.5px solid #000;
    border-radius: 8px;
    font-size: 16px;
    box-sizing: border-box;
  }

  .pay-btn {
    background-color: #e0d6c1;
    border: none;
    padding: 14px;
    width: 100%;
    border-radius: 8px;
    color: black;
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
    margin-top: 12px;
    font-weight: bold;
  }
  .pay-btn:focus, .pay-btn:active {
    color: black;
    outline: none;
  }

  .pay-btn:disabled {
    background-color: #b0b0b0;
    cursor: not-allowed;
  }
}