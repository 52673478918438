.copayDefault {
  .header {
    display: flex;
    justify-content: flex-start;
    padding: 10px 15px;
  }

  .logo img {
    width: 80px;
    height: auto;
  }
  .container {
    background-color: white;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 360px;
    text-align: center;
  }
  .qr-code {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
  }
  .qr-code img {
    width: 170px;
    height: 170px;
  }
  .download-btn {
    background-color: #f8f8f8;
    border: 1px solid #b59f78;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 10px auto;
    transition: background 0.2s;
  }
  .download-btn:hover {
    background-color: #ebe5db;
  }
  .download-btn img {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
  .payment-info {
    text-align: left;
    margin-top: 12px;
  }
  .payment-info h3 {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 14px;
  }
  .info-block {
    display: flex;
    align-items: center;
    background: #f6f6f6;
    border-radius: 6px;
    padding: 10px 12px;
    margin-bottom: 12px;
    justify-content: space-between;
    border: 1px solid #b59f78;
  }
  .info-label {
    font-weight: bold;
    font-size: 11px;
    margin-right: 10px;
    flex-shrink: 0;
    width: 100px;

    @media (max-width: 400px) {
      width: 80px;
    }
  }
  .info-value {
    flex-grow: 1;
    padding: 6px 10px;
    background: white;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 11px;
    text-align: left;
    min-width: 140px;
    margin-right: 8px;

    @media (max-width: 400px) {
      min-width: 80px;
    }
  }
  .copy-btn {
    background: #f6f6f6;
    border: 1px solid #b59f78;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 11px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: background 0.2s;
  }
  .copy-btn:hover {
    background: #ebe7e2;
  }
  .copy-btn img {
    width: 12px;
    height: 12px;
  }

}