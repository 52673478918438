.wrapper-input {
  .PhoneInput {
    display: block;
    border-radius: 20px;
  }

  .PhoneInputInput {
    padding-left: 65px;
  }

  .PhoneInputCountry {
    position: absolute;
    width: 40px;
    left: 15px;
    position: relative;
    align-self: stretch;
    align-items: center;
    z-index: 1;
  }

  .PhoneInputCountryIcon {
    position: absolute;
    left: 0;
    top: 15px;
  }

  .PhoneInputCountrySelect {
    position: absolute;
    top: 8px;
    left: 15px;
    height: 70%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }

  .PhoneInputCountrySelectArrow {
    display: none;
  }
}
