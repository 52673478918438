@import "../../../scss-variables.scss";

.wrapper-custom-select {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  label {
    line-height: 27px;
    font-weight: 400;
    color: var(--color-shade300);
    font-size: var(--fontSize-body1);
    align-self: center;
  }

  select {
    border: 1px solid var(----color-shade200);
    box-sizing: border-box;
    border-radius: 20px;
    width: 418px;
    height: 67px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 0 20px 30px;
    width: 418px;
    border: 1px solid var(--color-shade200);
    font-size: var(--fontSize-body1);
    text-align: left;
    text-align-last: left;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../../assets/img/down-arrow.png");
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: center;

    &:focus {
      outline: none;
      border: 1px solid var(--color-primary);
      background-image: url("../../../assets/img/back.png");
    }
  }

  option {
    text-align: left;
  }
}

@media only screen and (max-width: 675px) {
  .wrapper-custom-select {
    flex-direction: column;

    select {
      width: 100%;
    }

    label {
      padding-top: 0;
      padding-bottom: 10px;
      align-self: flex-start;
    }
  }
}
