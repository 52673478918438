@import "../../../scss-variables.scss";

.wrapper-input {
  .select-custom {
    &__control {
      border: none;
      border-bottom: 1px solid #c2c3d1 !important;
      border-radius: 0;

      &--menu-is-open {
        .select-custom__indicator svg {
          transform: rotate(180deg);
        }
      }
    }

    &.error .select-custom__control {
      border-bottom: 1px solid var(--color-error) !important;
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      svg {
        fill: var(--color-primary);
        transform: rotate(0);
        transition: all 200ms ease 0ms;
      }
    }

    &__input {
      font-size: 15px !important;
      font-weight: 500 !important;
      color: #000 !important;
      font-family: "Roboto" !important;

      @media (max-width: 480px) {
        font-size: 16px !important;
        font-weight: 400 !important;
      }
    }

    &__single-value {
      font-size: 15px;
      font-weight: 500;
      color: #000;

      @media (max-width: 480px) {
        font-size: 16px;
        font-weight: 400;
      }
    }

    &__menu {
      padding: 12px;
      box-shadow: 5px 5px 60px 0 rgba(38, 38, 115, 0.1);
      border-radius: 10px;

      &-list {
        max-height: 185px;
        scrollbar-color: rgba(170, 170, 170, 0.65) rgba(0, 0, 0, 0);
      }
    }

    &__option {
      font-size: 14px;
      padding: 8px 12px;
      border-radius: 10px;
      color: #000;

      &:not(:last-of-type) {
        margin-bottom: 4px;
      }

      &--is-focused,
      &--is-selected {
        background-color: var(--color-shade100);
        color: var(--color-primary);
      }
    }

    &__value-container {
      padding-left: 28px;
    }
  }
}
