.custom-footer {
  width: 100vw;
  background: linear-gradient(139.05deg, #163758 5.41%, #0d2944 92.94%);
  margin-top: 100px;
}
.inner-footer-logo {
  max-width: 210px;
  width: 100%;
  height: auto;
  display: block;
  margin: 15px auto;
}
.footer-text {
  max-width: 787px;
  margin: 10px auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
}
hr {
  width: 90%;
  margin-top: 40px;
}
.footer-bottom-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: rgba(255, 255, 255, 0.35);
}
.tc_wrapper {
  border: 2px solid var(--color-primary);
  border-radius: 15px;
  padding: 15px;
  & .checkbox-wrapper {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    line-height: 27px;
    font-weight: 400;
    color: var(--color-shade300);
    font-size: var(--fontSize-body1);
    & label {
      cursor: pointer;
    }
    & input {
      width: 20px;
      height: 20px;
      border: 1px solid var(--color-shade200);
      border-radius: 20px;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 584px) {
  .custom-footer {
    margin-top: 0;
  }
  .footer-text {
    width: 80%;
  }
}
