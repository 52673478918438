.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  form {
    max-width: 454px;
    min-width: 350px;
    width: 100%;
    box-shadow: 5px 5px 60px rgb(38 38 115 / 10%);
    border-radius: 30px;
    padding: 14px 30px 17px 29px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    position: relative;
    margin-top: 16px;
    padding-bottom: 35px;

    @media (max-width: 480px) {
      margin-top: 0;
      padding: 14px 18px 20px 18px;
      padding-bottom: 25px;
    }
    
    @media (max-width: 370px) {
      min-width: 315px;
    }
    
    .logo-timer {
      display: flex;
      margin-bottom: 20px;
      justify-content: space-between;
    }

    .navbar { 
      margin: 0 0 8px 0;
    }

    .close-only {
      justify-content: flex-end;
    }
    
    .method-icons {
      display: flex;
      gap: 16px;

      .icon {
        width: 64px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--color-shade100);

        @media (max-width: 767px) {
          width: 60px;
          height: 45px;
        }

        svg {
          @media (max-width: 767px) {
            width: 44px !important;
          }
        }
      }
    }
  }
}

.method-label2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 12px;

  @media (max-width: 767px) {
    font-size: 18px;
  }

  div {
    border-radius: 10px;
  }
}
