.inpayVa {
  .container {
    background-color: #fff;
    padding: 40px 30px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    text-align: center;
    width: 400px;
    animation: fadeInUp 0.5s ease-in-out;
  }
  @keyframes fadeInUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  h1 {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
  }
  .account-number {
    font-size: 24px;
    font-weight: bold;
    color: #2C3E50;
    margin-bottom: 30px;
    background-color: #f7f9fc;
    padding: 15px;
    border-radius: 8px;
    letter-spacing: 2px;
    transition: background-color 0.3s;
  }
  .account-number:hover {
    background-color: #eaf1f8;
  }
  .instructions {
    font-size: 16px;
    color: #666;
    margin-bottom: 40px;
  }
  .button {
    padding: 12px 25px;
    background-color: #3498db;
    color: #fff;
    text-decoration: none;
    border-radius: 30px;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    box-shadow: 0 5px 15px rgba(52, 152, 219, 0.4);
  }
  .button:hover {
    background-color: #2980b9;
  }
  .footer {
    margin-top: 30px;
    font-size: 12px;
    color: #888;
  }
  @media (max-width: 500px) {
    .container {
      width: 90%;
      padding: 20px;
    }
    h1 {
      font-size: 22px;
    }
    .account-number {
      font-size: 18px;
      padding: 10px;
    }
  }
  img {
    width:220px;
  }
}