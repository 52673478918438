.wallet-data {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
}

.wallet-address-qr {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.warning {
  padding: 16px;
  border-radius: 5px;
}

.message_1 {
  color: #857a66;
  background-color: #fdf8e3;
}

.copy-to-clipboard-button {
  display: flex;
  cursor: pointer;
  height: 16px;
  width: 16px;
  background-image: url("../../assets/img/copy.png");
}

.copy-to-clipboard-button:active {
  background-color: #fefefe;
}

.copied {
  background-color: #9adf9f;
}

.deposit-to {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  margin-top: 24px;
}

.wallet {
  .method-label {
    font-weight: 700;
    font-size: var(--fontSize-header);
    line-height: 150%;
  }

  .fixed-amount-details {
    .fixed-amount {
      background: none;
    }
  }
}

.address {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: var(--color-primary);
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  & p {
    margin: 8px 0;
  }
}
.blockchain {
  text-align: center;
  & p {
    margin: 12px 0;
  }
}

.info-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;

  .info-icon {
    display: flex;
    height: 36px;
    width: 36px;
    background-image: url("../../assets/img/info.png");
  }

  .info-mesage {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    flex: 1;
    color: #00ba1e;
  }
}

@media only screen and (max-width: 584px) {
  .info-wrapper {
    flex-direction: column;

    .info-mesage {
      text-align: center;
    }
  }
}
