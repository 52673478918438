@import "../../../scss-variables.scss";

.form-fields {
  display: flex;
  flex-direction: column;
}

.form-footer {
  font-size: 0.9em;
  font-weight: bold;
  text-align: center;
}

.button-submit {
  border: none;
  outline: none;
  background-image: var(--color-primaryGradient200);
  cursor: pointer;
  border-radius: 15px;
  width: 100%;
  height: 45px;
  color: var(--color-white100);
  font-weight: 600;
  font-size: var(--fontSize-body2);
  line-height: 150%;
  align-self: center;

  &:hover {
    background: var(--color-shade400);
  }

  &:active {
    background: var(--color-white100);
    color: var(--color-primary);
  }
}
