.biller5qr {
  .container {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    text-align: center;
    width: 90%;
    max-width: 400px;
    animation: fadeInUp 0.5s ease-in-out;
  }

  #qrcode {
    display: block;
    margin: 0 auto 20px;
    width: 100%;
    max-width: 250px;
    aspect-ratio: 1 / 1;
  }

  .instructions {
    font-size: 14px;
    color: #666;
    margin-inline: 30px;
    margin-bottom: 20px;
  }

  #img {
    display: block;
    margin: 0 auto 20px;
    height: 50px;
  }
  #qrcode_download {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    color: #000;
    background-color: #FFF;
    text-decoration: none;
  }

  .button_download_container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px 15px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    color: #000;
    background-color: #FFF;
    border: 2px solid #b1a281;
    border-radius: 15px;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  #download_img {
    width: 20px;
  }

  @keyframes fadeInUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  h2 {
    font-size: 22px;
    color: #333;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 480px) {
    .container {
      padding: 15px;
    }

    h2 {
      font-size: 18px;
    }

    #qrcode {
      max-width: 180px;
    }

    .instructions {
      font-size: 12px;
      margin-bottom: 15px;
    }

    #img {
      height: 40px;
    }
  }
}
