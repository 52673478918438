@import "../../scss-variables.scss";

.wrapper-input {
  width: 100%;
  margin-bottom: 26px;
  justify-content: space-between;
  position: relative;
  flex-direction: column;

  label {
    color: #8a939c;
    font-weight: 500;
    font-size: 15px;

    @media (max-width: 480px) {
      font-weight: 400;
      font-size: 14px;
    }
  }

  input {
    border: none;
    border-bottom: 1px solid var(--color-shade200);
    padding: 7px 36px 7px 28px;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    font-family: "Roboto";

    @media (max-width: 480px) {
      font-weight: 400;
      font-size: 16px;
      padding: 4px 36px 4px 24px;
    }

    ::placeholder {
      color: #8a939c;
    }

    &:focus {
      outline: none;
      color: var(--color-primary);
    }

    &:focus~.left-slot path {
      fill: var(--color-primary);
    }
  }

  .card-icon {
    position: absolute;
    right: 0;
    width: 30px;
  }

  .input-container {
    position: relative;

    .left-slot {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      width: 20px;

      path {
        fill: rgb(161, 163, 190);
      }

      @media (max-width: 480px) {
        width: 16px;
      }
    }

    .right-slot {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
    }

    .error__message-block {
      position: absolute;
      right: 0;
      top: 100%;
      font-size: 11px;
      line-height: 1;
      padding-top: 3px;
      color: var(--color-error);

      @media (max-width: 480px) {
        font-size: 10px;
      }

      p {
        margin: 0;
      }
    }
  }

  .cardBrand {
    height: 24px;
    position: absolute;
    right: 0px;
    top: 27px;
  }

  .error__custom-input {
    font-size: 12px;
    color: var(--color-error);
    position: absolute;
    bottom: 0;
    right: 15px;
  }

  &.customerPersonalId {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 675px) {
  .wrapper-input {
    input {
      width: 100%;
      margin: 0;
    }

    label {
      align-self: flex-start;
      padding-right: 0;
    }
  }

  .amount-with-range {
    width: 100%;
    margin-right: 0;

    .range-and-toggle {
      margin-left: 0;
    }
  }
}

.error__img {
  position: absolute;
  bottom: 5px;
  right: 2px;
}

.error__message-block {
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: -31px;
  right: 1px;
}