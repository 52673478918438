.timer-wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-left: auto;

  .timer-state {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-shade100);
    border-radius: 5px;
    color: var(--color-shade300);
  }

  span {
    font-weight: 600;
    font-size: var(--fontSize-header);
    line-height: 150%;
    text-align: center;
    color: var(--color-shade300);
  }
}

.timer-wrapper.time-warning {
  .timer-state {
    background: var(--color-error200);
    border-radius: 5px;
    color: var(--color-error);
  }

  span {
    color: var(--color-error200);
  }
}

@media only screen and (max-width: 584px) {
  .timer-wrapper {
    margin: 15px 0;
  }
}
