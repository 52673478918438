:root {
  --color-primary: #7b6dec;
  --color-primaryGradient200: linear-gradient(180deg, #6656e9 0%, #7b6dec 100%);
  --color-primaryDarkerGradient: linear-gradient(
    139.05deg,
    #204265 5.41%,
    #0d2944 92.94%
  );
  --color-primary300: rgba(36, 94, 254, 0.1);
  --color-success: #00ba1e;
  --color-success200: rgba(0, 186, 30, 0.15);
  --color-declined: #f32c2c;
  --color-error: #d92200;
  --color-error200: rgba(217, 34, 0, 0.15);
  --color-pending: #ffb801;
  --color-shade400: #06192c;
  --color-shade300: #7b6dec;
  --color-shade200: #c2c3d1;
  --color-shade100: #f2f0fd;
  --color-white100: #ffffff;

  --light-blue: #3b5f84;
  --blue: #0f2b48;
  --navy-blue: #0b243e;
  --dark-blue-gradient: linear-gradient(
    139.05deg,
    #163758 5.41%,
    #0d2944 92.94%
  );

  --fontSize-header: 18px;
  --fontSize-body1: 16px;
  --fontSize-body2: 14px;
  --fontSize-body3: 12px;
  --fontSize-body4: 10px;

  //CUSTOM PHONE INPUT
  --PhoneInputCountryFlag-height: 1.2em;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Bold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Black.ttf");
  font-weight: 700;
}

html {
  background: url(./assets/img/bg.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(----color-shade200);
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(----color-shade200);
}

::-ms-input-placeholder {
  /* IE 10+ */
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(----color-shade200);
}

::-moz-placeholder {
  /* Firefox 18- */
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(----color-shade200);
}

.animated-form {
  animation: openForm 0.35s;
  animation-timing-function: ease-in;
}

@keyframes openForm {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.logo {
  max-width: 210px;
  width: 100%;
  height: auto;
  margin-top: 15px;
  margin-bottom: 16px;
}

.inner-form-logo {
  width: 115px;
  justify-self: center;

  @media (max-width: 480px) {
    width: 110px;
  }
}

.button-cancel, .button-back {
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--color-shade100);

  @media (max-width: 767px) {
    position: static;
  }
  @media (max-width: 480px) {
    width: 30px;
    height: 30px;
  }
}

.button-cancel  {
  margin-left: auto;
  &.left {
    margin-left: unset;
  }
}

.button-back:hover,
.button-cancel:hover {
  background: var(--color-shade100);
}

.button-cancel:active {
  background: var(--color-primary);
}

@media only screen and (max-width: 584px) {
  .logo {
    margin-top: 14px;
    margin-bottom: 27px;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 12px;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 12px;
  }

  ::-ms-input-placeholder {
    /* IE 10+ */
    font-size: 12px;
  }

  ::-moz-placeholder {
    /* Firefox 18- */
    font-size: 12px;
  }
}

.error {
  // background: var(--color-error200) !important;
  border-bottom-color: rgba(217, 34, 0, 0.2) !important;
  color: var(--color-error) !important;

  path {
    fill: var(--color-error) !important;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 15px;
  }

  @media (max-width: 480px) {
    select:focus,
    textarea:focus,
    input:focus {
      font-size: 16px;
    }
  }
}

// Fix for Iphone: blinking inputs
@media (max-width: 480px) {
  * {
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
  }
}
