
.biller5va {
  .container {
    background-color: #fff;
    padding: 40px 30px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    text-align: center;
    width: 100%;
    max-width: 400px;
    animation: fadeInUp 0.5s ease-in-out;
  }

  h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 20px;
  }

  .account-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f9fc;
    padding: 10px 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    font-size: 18px;
    color: #2C3E50;
    font-weight: bold;
    letter-spacing: 1px;
    flex-wrap: wrap;
  }

  .copy-icon {
    cursor: pointer;
    margin-left: 10px;
    transition: color 0.3s;
    display: flex;
    align-items: center;
  }

  .copy-icon:hover {
    color: #2980b9;
  }

  .instructions {
    font-size: 14px;
    color: #666;
    line-height: 1.6;
  }

  img {
    display: block;
    margin: 0 auto 20px auto;
    height: 80px;
  }

  .custom-copy-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px; /* Расстояние между иконкой и текстом */
    padding: 12px 18px;
    font-size: 12px;
    font-weight: bold;
    color: #b1a281;
    background: #FFF;
    border: 2px solid #b1a281;
    border-radius: 16px;
    cursor: pointer;
    transition: background 0.3s, color 0.3s, transform 0.2s;
    width: 100%;
    max-width: 200px; /* Ограничение ширины */
    margin: 10px auto; /* Центрирование */
  }

  .custom-copy-button:hover {
    background: white;
  }

  .custom-copy-button:active {
    transform: scale(0.95);
  }

  svg {
    width: 20px;
    height: 20px;
    fill: #b1a281;
  }

  svg:hover {
    fill: #b1a281;
  }

  .toast {
    visibility: hidden;
    min-width: 250px;
    margin: auto;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    padding: 15px;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.5s ease-in-out, visibility 0.5s;
  }

  .toast.show {
    visibility: visible;
    opacity: 1;
  }

  @media (max-width: 480px) {
    .container {
      padding: 20px;
      border-radius: 8px;
    }

    h1 {
      font-size: 20px;
    }

    h2 {
      font-size: 16px;
    }

    .account-container {
      flex-direction: column;
      text-align: center;
      font-size: 16px;
      padding: 8px;
    }

    img {
      height: 60px;
    }

    svg {
      width: 18px;
      height: 18px;
    }

    .instructions {
      font-size: 12px;
    }
    .custom-copy-button {
      font-size: 10px;
      padding: 10px 14px;
      max-width: 180px;
    }
  }
}
.animated-form {
  display: flex;
  justify-content: center;
}