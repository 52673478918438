.form-card {
  .message-wrapper-amount {
    display: flex;
    flex-direction: column;

    .message-title {
      color: var(--color-primary);
      font-weight: 500;
      font-size: 14px;
    }

    .message-text {
      font-size: 12px;
      color: #8a939c;
    }
  }

  .amount {
    font-weight: 600;
    background-color: var(--color-shade100);
    margin-bottom: 8px;
    border-radius: 10px;
    padding: 15px 0;
    display: flex;
    justify-content: space-around;

    @media (max-width: 480px) {
      padding: 16px 0;
    }

    .amount-container {
      border-bottom: 1px solid var(--color-shade200);
      display: flex;
      align-items: center;
    }

    .amount-value {
      font-weight: 600;
      font-size: 19px;
      color: var(--color-primary);

      @media (max-width: 480px) {
        font-size: 16px;
      }
    }

    .amount-icon {
      margin-right: 10px;

      @media (max-width: 480px) {
        width: 18px;
      }

      path {
        fill: var(--color-primary);
      }
    }
  }

  .input-row {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;

    .expiry-wrapper,
    .cvv-wrapper {
      flex-shrink: 0;
      width: 50%;

      .input-container {
        input {
          padding-right: 5px;
          width: 50%;

          @media (max-width: 480px) {
            width: 93px;
          }
        }

        .error__message-block {
          right: initial;
          left: 0;
        }
      }
    }
  }

  .cvv-secure {
    font-family: "text-security-disc";
    -webkit-text-security: disc;
  }

  // --- style for save cards: --- //
  .save-card {
    margin-bottom: 8px;
    padding: 0 16px;
    padding-right: 35px;
    height: 44px;

    &__label-container {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    input {
      display: none;

      &:checked + .save-card__label-container {
        label:after {
          content: "";
          display: block;
          height: 16px;
          width: 15px;
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translate(0, -50%);
          background: url(../../../../assets/img/form-icons/ic_done.svg);
        }

        label:before {
          background-color: var(--color-primary);
        }
      }
    }

    label {
      font-size: 12px;
      font-weight: 400;
      color: #0d2944;
      cursor: pointer;

      @media (max-width: 480px) {
        cursor: initial;
      }

      &:before {
        content: "";
        display: block;
        height: 20px;
        width: 20px;
        border-radius: 5px;
        border: 2px solid var(--color-primary);
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }

  .list-saveCards {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: 230px;
    overflow-y: auto;
    margin: 20px 0;

    & .saveCard__item {
      width: 100%;
      height: 70px;
      background: #f5f7ff;
      border: 2px solid #d8daf0;
      color: #a1a3be;
      border-radius: 20px;
      cursor: pointer;
      margin-bottom: 10px;
      display: flex;
      position: relative;
      flex-shrink: 0;

      @media (max-width: 767px) {
        width: 100%;
        align-items: center;
        padding: 0 15px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      &-active {
        background: rgba(36, 94, 254, 0.11);
        border: 2px solid var(--color-primary);
        border-radius: 20px;
        margin-bottom: 10px;
        color: var(--color-primary);
      }

      & .saveCard__text {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        margin: 0 auto;
        padding-left: 60px;

        @media (max-width: 767px) {
          padding-left: 20px;
        }

        @media (max-width: 480px) {
          padding-left: 0px;
        }
      }

      & .saveCard__img {
        width: 34px;
        height: 25px;
        position: absolute;
        top: 20px;
        left: 25px;

        @media (max-width: 767px) {
          height: auto;
          width: 35px;
          position: static;
        }
      }

      & .delete-btn {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #ffffff;
        box-shadow: 5px 5px 60px rgba(38, 38, 115, 0.1);
        border: none;
        background-image: url(../../../../assets/img/delete_card.png);
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        &__wrapper {
            width: 40px;
            height: 40px;
            margin-top: 13px;
            margin-right: 17px;
            @media (max-width: 767px) {
              margin: 0;
            }
        }
      }
    }
  }

  .new-card-btn-container {
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }

  .nevCard-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    max-width: 260px;

    span {
      font-weight: 400;
      font-size: 24px;
      display: block;
      margin-right: 15px;
    }
  }

  @media only screen and (max-width: 675px) {
    .nevCard-btn {
      margin-top: 10px;
    }
  }

  // --- :style for save cards --- //

  // --- style for show qr-code: --- //
  .qr-code {
    width: 100%;
    height: 44px;
    background-color: #f2f0fd;
    border: 1px solid #e6e3fb;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    margin-bottom: 8px;

    &__block {
      display: flex;
      align-items: center;
    }

    &__img {
      margin-right: 8px;

      path {
        fill: var(--color-primary);
      }
    }

    &__title {
      font-size: 12px;
      line-height: 150%;
      color: #0d2944;
    }

    &__btn {
      width: 118px;
      height: 29px;
      background: var(--color-primaryGradient200);
      color: var(--color-white100);
      box-shadow: 10px 10px 60px rgba(53, 107, 255, 0.31);
      border-radius: 5px;
      border: none;
      cursor: pointer;
      margin-right: 10px;

      &:hover {
        background-color: var(--color-shade400);
      }
    }
  }

  .d-none {
    display: none;
  }

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(6, 25, 44, 0.36);
    backdrop-filter: blur(5px);
    width: 100%;
    height: 100%;
    z-index: 9;

    &__block {
      position: absolute;
      top: 20px;
      left: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      transform: translateX(-50%);
      width: 424px;
      height: 500px;
      background-color: #fff;
      box-shadow: 5px 5px 60px rgba(38, 38, 115, 0.1);
      padding: 20px;
      border-radius: 30px;
      z-index: 99;
    }

    &__btn-close {
      width: 30px;
      height: 30px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      cursor: pointer;

      &:hover {
        background: var(--color-shade100);
      }

      &:active {
        background: var(--color-primary);
      }
    }

    &__title {
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
    }

    &__qr-code {
      margin-bottom: 30px;
    }

    &__btn-cancel {
      width: 145px;
      height: 45px;
      color: var(--color-primary);
      background: rgba(36, 94, 254, 0.11);
      border-radius: 10px;
      border: none;
      cursor: pointer;

      &:hover {
        background: var(--color-shade400);
        color: var(--color-white100);
      }
    }
  }

  @media only screen and (max-width: 675px) {
    .popup {
      &__block {
        top: 60px;
        width: 335px;
      }
    }
  }
  
  .submit-wrapper {
    display: flex;
    justify-content: center;

    .button-submit {
      line-height: 1;
      height: auto;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      padding: 14px 0;
      width: 100%;
      margin-bottom: 0;
      border-radius: 15px;

      @media (max-width: 767px) {
        padding: 14px 0;
      }
    }
  }
}

.brand-row {
  display: flex;
  justify-content: space-between;
  padding: 25px 20px 11px 20px;
}

@media only screen and (max-width: 675px) {
  .brand-row {
    padding: 24px 24px 0 24px;
  }
}

.number-row {
  padding: 0 25px;
}

.name-expiry-row {
  padding: 0 28px;
  display: flex;
  position: relative;

  .expiry-side-label {
    display: none;
    align-self: flex-end;
    padding: 0 10px;
    color: rgba(255, 255, 255, 0.35);
    font-weight: 400;
    font-size: var(--fontSize-body3);
    line-height: 150%;
    text-align: end;
  }

  .expiry-wrapper {
    width: 37%;

    label {
      align-self: flex-end;
      padding-right: 0;
    }
  }

  .error {
    &__img {
      position: absolute;
      bottom: 3px;
      right: 5px;
    }

    &__message-block {
      position: absolute;
      right: -4px;
      top: 50px;
    }

    &__arrow-icon {
      position: absolute;
      top: 6px;
      right: 10px;
    }

    &__message {
      width: 100%;
      border-radius: 5px;
      padding: 1px 8px;
      background-color: #fff;
      color: #000;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      border: 1px solid #ff0000;

      &--expiryDate {
        width: 105px;
      }
    }
  }
}

.brand-logo {
  img {
    height: 24px;
  }
}

.fullWidth {
  width: 100%;
  justify-content: center;
}
