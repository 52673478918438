@import "../../scss-variables.scss";

.wrapper-progress {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.load-wrapper {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.load-image {
  width: 70px;
  height: 70px;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.load-text {
  margin-top: 20px;
  text-align: center;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.wrapper-state {
  width: 125%;
  box-shadow: 5px 5px 60px rgb(38 38 115 / 10%);
  border-radius: 30px;
  max-width: 579px;
  padding: 0 25px 5px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  align-items: center;
  padding-bottom: 35px;

  & .state-image {
    width: 168px;
    height: auto;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-top: 16px;
  }

  @media (max-width: 480px) {
    margin-top: 0px;
  }

  &.wrapper-threeds {
    align-items: flex-start;

    h2 {
      font-size: 20px;
      color: var(--color-primary);
    }

    .threeds-form {
      max-width: 454px;
      width: 100%;
      box-shadow: none;
      border-radius: none;
      padding: 0px;
    }
  }

  .otp-secure {
    font-family: "text-security-disc";
    -webkit-text-security: disc;
  }
}

.title-state {
  text-align: center;
  font-size: var(--fontSize-header);
  line-height: 36px;
  font-weight: 700;
}

.COMPLETED {
  color: var(--color-success);
}

.ERROR,
.DECLINED {
  color: var(--color-declined);
}

.CANCELLED {
  color: var(--color-pending);
}

.link-return-shop {
  border: none;
  outline: none;
  background-image: var(--color-primaryGradient200);
  cursor: pointer;
  border-radius: 10px;
  width: 258px;
  height: 45px;
  color: var(--color-white100);
  font-weight: 600;
  font-size: var(--fontSize-body2);
  line-height: 150%;
  align-self: center;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 15px;

  &:hover {
    background: var(--color-shade400);
  }

  &:active {
    background: var(--color-white100);
    color: var(--color-primary);
  }
}

.amount-currency {
  display: flex;
  font-weight: 700;
  font-size: var(--fontSize-header);
  line-height: 36px;
  color: var(--color-primary);
  background-color: var(--color-shade100);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  border-radius: 50%;
}

// css style for Cascading components
.navbar {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin: 10px 0 24px 0;

  &__img {
    width: 115px;
    height: 45px;

    svg {
      width: inherit;
      height: inherit;
    }
  }
}

.wrapper-payment-method {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  margin-bottom: 20px;

  .method-label {
    font-family: "Roboto";
    font-size: 18px;
    font-weight: 400;
  }

  .logo-wrapper {
    width: 69px;
    height: 40px;
    background: var(--color-shade100);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;

    svg {
      width: 18px;
      height: 14px;
    }
  }
}

.state-threeds {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 15px;

  &__title {
    font-weight: 500;
    width: 100px;
    color: #8a939c;
  }
}

.state-alternative {
  &__title {
    width: auto;
  }
}

.wrapper-pay {
  padding: 14px 30px 35px 29px;
  align-items: flex-start;
  max-width: 440px;

  @media (max-width: 480px) {
    max-width: 440px;
  }

  @media (max-width: 370px) {
    .container form {
      max-width: 315px;
    }
  }
}

.state-pay {
  &__title {
    font-weight: 500;
    color: #000000;
    font-size: 18px;
    margin: 5px 0;
  }

  &__text {
    font-weight: 400;
    color: var(--color-primary);
    font-size: 14px;
  }

  &__info {
    display: flex;
    gap: 20px;
    align-items: center;

    @media (max-width: 480px) {
      flex-direction: column;
      gap: 0;
      align-items: flex-start;
    }

    &-item {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  &__button {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    width: 100%;

    #apple-pay-button {
      --apple-pay-button-width: 236px;
      --apple-pay-button-height: 64px;
      --apple-pay-button-border-radius: 3px;
      --apple-pay-button-padding: 0px 0px;
      --apple-pay-button-box-sizing: border-box;
    }

    #apple-pay-button {
      width: 236px;
      height: 64px;
      border-radius: 10px;
      background-color: black;
      color: white;
      border: none;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      line-height: 64px;
      cursor: pointer;
    }

    #apple-pay-button:focus {
      outline: none;
    }

    #apple-pay-button:disabled {
      background-color: gray;
      cursor: not-allowed;
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 100%;
    }

    svg {
      width: 24px;
      height: 24px;
      padding-bottom: 4px;
    }

  }
}

.cascading-instructions {
  text-align: center;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  white-space: pre-line;
}

.state-image-cascading {
  margin: 25px 0;
  width: 168px;
  height: auto;
}

.errorCode-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #646464;
  white-space: pre-line;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 30px;
  gap: 10px;
  background: rgba(255, 184, 1, 0.06);
  border: 2px solid #ffb801;
  border-radius: 20px;
}

.receipt-btn {
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  background: #f5f7ff;
  width: 258px;
  height: 45px;
  color: #222;
  font-weight: 600;
  font-size: var(--fontSize-body2);
  line-height: 150%;
  align-self: center;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 25px;
}

.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 78px;
  height: 78px;
  color: #6656e9;
}

.loader:before,
.loader:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}

.loader:after {
  color: #7b6dec;
  transform: rotateY(70deg);
  animation-delay: .4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {

  0%,
  100% {
    box-shadow: .2em 0px 0 0px currentcolor;
  }

  12% {
    box-shadow: .2em .2em 0 0 currentcolor;
  }

  25% {
    box-shadow: 0 .2em 0 0px currentcolor;
  }

  37% {
    box-shadow: -.2em .2em 0 0 currentcolor;
  }

  50% {
    box-shadow: -.2em 0 0 0 currentcolor;
  }

  62% {
    box-shadow: -.2em -.2em 0 0 currentcolor;
  }

  75% {
    box-shadow: 0px -.2em 0 0 currentcolor;
  }

  87% {
    box-shadow: .2em -.2em 0 0 currentcolor;
  }
}